import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { fetchCardDetails, fetchCardInventory, updateCardInventory } from '../../api/cards';
import Card from '../../components/Card';
import DiceRoller from '../../components/DiceRoller';
import { combineBattingRecords, combinePitchingRecords, formatAverage } from '../../utils/statsUtils';
import { teamNameTranslator } from '../../helpers/teamHelpers';
import GLOBALS from '../../config/globals';

const CardDetailsPage = () => {
    const { currentUser } = useAuth();
    const { cardID } = useParams();
    const location = useLocation();
    const [card, setCard] = useState(location.state?.card || null);
    const [cardInventory, setCardInventory] = useState(null);
    const [loading, setLoading] = useState(!card);
    const [error, setError] = useState(null);
    const [variants, setVariants] = useState([]);

    useEffect(() => {
        if (!card || !card.year) return;

        const availableVariants = [`${card.year}-base`];
        if (cardInventory && cardInventory.Series) {
            // Assuming cardInventory.Series is an array of unlocked series
            cardInventory.Series.forEach(series => availableVariants.push(series.seriesKey));
        }
        setVariants(availableVariants);
    }, [cardInventory, card]);

    useEffect(() => {
        if (!card) {
            const getCard = async () => {
                setLoading(true);
                setError(null);
                try {
                    const data = await fetchCardDetails(cardID);
                    setCard(data);
                } catch (error) {
                    setError('Error fetching card details');
                    console.error('Error fetching card details:', error);
                } finally {
                    setLoading(false);
                }
            };
            getCard();
        }

        const getCardInventory = async () => {
            try {
                const data = await fetchCardInventory(cardID);
                setCardInventory(data);
            } catch (error) {
                setError('Error fetching card inventory');
                console.error('Error fetching card inventory:', error);
            }
        };
        getCardInventory();
    }, [card, cardID]);

    // Use another useEffect to attach cardInventory to card
    useEffect(() => {
        if (card && cardInventory) {
            card.CardInventory = cardInventory;
        }
    }, [cardInventory, card]);

    const handleVariantClick = async variant => {
        try {
            await updateCardInventory(cardInventory.inventoryID, variant);
            setCardInventory(prev => ({ ...prev, displayKey: variant }));
        } catch (error) {
            console.error('Error updating variant:', error);
        }
    };

    const calculateBattingStats = batting => {
        const singles = batting.H - (batting['2B'] + batting['3B'] + batting.HR);
        const average = formatAverage(batting.H / batting.AB);
        const obp = formatAverage(
            (batting.H + batting.BB + batting.HBP) / (batting.AB + batting.BB + batting.HBP + batting.SF)
        );
        const slugging = formatAverage((singles + batting['2B'] * 2 + batting['3B'] * 3 + batting.HR * 4) / batting.AB);
        const ops = formatAverage(parseFloat(obp) + parseFloat(slugging));
        return { average, obp, slugging, ops };
    };

    const calculatePitchingStats = pitching => {
        const era = ((pitching.ER * 9) / (pitching.IPouts / 3)).toFixed(2);
        const k9 = ((pitching.SO / (pitching.IPouts / 3)) * 9).toFixed(2);
        const hr9 = ((pitching.HR / (pitching.IPouts / 3)) * 9).toFixed(2);
        const whip = ((pitching.BB + pitching.H) / (pitching.IPouts / 3)).toFixed(2);
        const bb9 = ((pitching.BB / (pitching.IPouts / 3)) * 9).toFixed(2);
        const h9 = ((pitching.H / (pitching.IPouts / 3)) * 9).toFixed(2);
        return { era, k9, hr9, bb9, h9, whip };
    };

    const calculateInventoryBattingStats = inventory => {
        const hits = (inventory.h1 || 0) + (inventory.h2 || 0) + (inventory.h3 || 0) + (inventory.hr || 0);
        const singles = inventory.h1;
        const average = inventory.ab ? formatAverage((hits || 0) / (inventory.ab || 0)) : 0;
        const obp = inventory.ab ? formatAverage((hits + inventory.bb) / (inventory.ab + inventory.bb)) : 0;
        const slugging = inventory.ab
            ? formatAverage((singles + inventory.h2 * 2 + inventory.h3 * 3 + inventory.hr * 4) / inventory.ab)
            : 0;
        const ops = formatAverage(parseFloat(obp) + parseFloat(slugging));
        return { average, obp, slugging, ops };
    };

    const calculateInventoryPitchingStats = inventory => {
        const k9 = inventory.ipOuts ? ((inventory.so / (inventory.ipOuts / 3)) * 9).toFixed(2) : 0;
        const bb9 = inventory.ipOuts ? ((inventory.bb / (inventory.ipOuts / 3)) * 9).toFixed(2) : 0;
        const h9 = inventory.ipOuts
            ? (((inventory.h1 + inventory.h2 + inventory.h3 + inventory.hr) / (inventory.ipOuts / 3)) * 9).toFixed(2)
            : 0;
        const hr9 = inventory.ipOuts ? ((inventory.hr / (inventory.ipOuts / 3)) * 9).toFixed(2) : 0;
        const whip = inventory.ipOuts
            ? (
                  (inventory.bb + inventory.h1 + inventory.h2 + inventory.h3 + inventory.hr) /
                  (inventory.ipOuts / 3)
              ).toFixed(2)
            : 0;
        const era = inventory.ipOuts ? ((inventory.runs || 0) / (inventory.ipOuts / 27)).toFixed(2) : 0;
        return { h9, bb9, hr9, k9, whip, era };
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="error">{error}</p>;
    }

    const combinedBatting = card.Battings ? combineBattingRecords(card.Battings) : null;
    const combinedPitching = card.Pitchings ? combinePitchingRecords(card.Pitchings) : null;

    return (
        <div className="card-detail-container">
            <h2>Card Details</h2>

            <div className="card-detail-page">
                <Card card={card} />
                <div className="card-stats">
                    {card.type === 'Batter' && combinedBatting ? (
                        <div>
                            <h3>Batting Stats ({card.year})</h3>
                            {combinedBatting.AB <= GLOBALS.LOW_AB ? <h3>* LOW AT BATS</h3> : <></>}
                            <p>Team: {teamNameTranslator(card.teamNameID)}</p>
                            <p>BA: {calculateBattingStats(combinedBatting).average}</p>
                            <p>OBP: {calculateBattingStats(combinedBatting).obp}</p>
                            <p>SLG: {calculateBattingStats(combinedBatting).slugging}</p>
                            <p>OPS: {calculateBattingStats(combinedBatting).ops}</p>
                            <p>Speed: {card.speed}</p>
                            <p>Games: {combinedBatting.G}</p>
                            <p>At Bats: {combinedBatting.AB}</p>
                            <p>Runs: {combinedBatting.R}</p>
                            <p>Hits: {combinedBatting.H}</p>
                            <p>Home Runs: {combinedBatting.HR}</p>
                            <p>RBIs: {combinedBatting.RBI}</p>
                            <p>Stolen Bases: {combinedBatting.SB}</p>
                            <p>Caught Stealing: {combinedBatting.CS}</p>
                            <p>Walks: {combinedBatting.BB}</p>
                            <p>Strikeouts: {combinedBatting.SO}</p>
                        </div>
                    ) : card.type !== 'Batter' && combinedPitching ? (
                        <div>
                            <h3>Pitching Stats ({card.year})</h3>
                            {combinedPitching.IPouts <= GLOBALS.LOW_IPOUTS ? <h3>* LOW INNINGS PITCHED</h3> : <></>}
                            <p>Team: {teamNameTranslator(card.teamNameID)}</p>
                            <p>ERA: {calculatePitchingStats(combinedPitching).era}</p>
                            <p>WHIP: {calculatePitchingStats(combinedPitching).whip}</p>
                            <p>K9: {calculatePitchingStats(combinedPitching).k9}</p>
                            <p>HR9: {calculatePitchingStats(combinedPitching).hr9}</p>
                            <p>H9: {calculatePitchingStats(combinedPitching).h9}</p>
                            <p>BB9: {calculatePitchingStats(combinedPitching).bb9}</p>
                            <p>Games: {combinedPitching.G}</p>
                            <p>Games Started: {combinedPitching.GS}</p>
                            <p>Wins: {combinedPitching.W}</p>
                            <p>Losses: {combinedPitching.L}</p>
                            <p>Complete Games: {combinedPitching.CG}</p>
                            <p>Shutouts: {combinedPitching.SHO}</p>
                            <p>Saves: {combinedPitching.SV}</p>
                            <p>Innings Pitched: {(combinedPitching.IPouts / 3).toFixed(1)}</p>
                            <p>Hits Allowed: {combinedPitching.H}</p>
                            <p>Earned Runs: {combinedPitching.ER}</p>
                            <p>Home Runs Allowed: {combinedPitching.HR}</p>
                            <p>Walks: {combinedPitching.BB}</p>
                            <p>Strikeouts: {combinedPitching.SO}</p>
                        </div>
                    ) : (
                        <p>No stats available for this card.</p>
                    )}
                </div>
                {card && cardInventory && (
                    <div className="card-inventory-stats">
                        <h3>Card Inventory Stats</h3>
                        {card.type === 'Batter' ? (
                            <div>
                                <p>EXP: {cardInventory.exp}</p>
                                <p>BA: {calculateInventoryBattingStats(cardInventory).average}</p>
                                <p>OBP: {calculateInventoryBattingStats(cardInventory).obp}</p>
                                <p>SLG: {calculateInventoryBattingStats(cardInventory).slugging}</p>
                                <p>OPS: {calculateInventoryBattingStats(cardInventory).ops}</p>
                                <p>AB: {cardInventory.ab}</p>
                                <p>Hits: {cardInventory.h1 + cardInventory.h2 + cardInventory.h3 + cardInventory.hr}</p>
                                <p>Runs: {cardInventory.runs}</p>
                                <p>RBI: {cardInventory.rbi}</p>
                                <p>Singles: {cardInventory.h1}</p>
                                <p>Doubles: {cardInventory.h2}</p>
                                <p>Triples: {cardInventory.h3}</p>
                                <p>Home Runs: {cardInventory.hr}</p>
                                <p>Strikeouts: {cardInventory.so}</p>
                                <p>Walks: {cardInventory.bb}</p>
                                <p>SB: {cardInventory.sb}</p>
                                <p>CS: {cardInventory.cs}</p>
                            </div>
                        ) : (
                            <div>
                                <p>Experience: {cardInventory.exp}</p>
                                <p>(E)RA: {calculateInventoryPitchingStats(cardInventory).era}</p>
                                <p>K9: {calculateInventoryPitchingStats(cardInventory).k9}</p>
                                <p>H9: {calculateInventoryPitchingStats(cardInventory).h9}</p>
                                <p>BB9: {calculateInventoryPitchingStats(cardInventory).bb9}</p>
                                <p>HR9: {calculateInventoryPitchingStats(cardInventory).hr9}</p>
                                <p>WHIP: {calculateInventoryPitchingStats(cardInventory).whip}</p>
                                <p>IP: {(cardInventory.ipOuts / 3).toFixed(1)}</p>
                                <p>Strikeouts: {cardInventory.so}</p>
                                <p>Walks: {cardInventory.bb}</p>
                                <p>Hits: {cardInventory.h1 + cardInventory.h2 + cardInventory.h3 + cardInventory.hr}</p>
                                <p>Singles: {cardInventory.h1}</p>
                                <p>Doubles: {cardInventory.h2}</p>
                                <p>Triples: {cardInventory.h3}</p>
                                <p>Home Runs: {cardInventory.hr}</p>
                                <p>Runs: {cardInventory.runs}</p>
                            </div>
                        )}
                    </div>
                )}
                {currentUser && currentUser.isAdmin && card && card.type === 'Batter' && <DiceRoller card={card} />}
            </div>
            <h3>Available Variants</h3>
            <div className="card-variants-page">
                {variants.map(variant => (
                    <div
                        key={variant}
                        className={`variant-container ${
                            cardInventory && (variant === cardInventory.displayKey ? 'active' : '')
                        }`}
                        onClick={() => handleVariantClick(variant)}
                    >
                        <img src={`/img/card/${variant}.png`} alt={variant} className="variant-image" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CardDetailsPage;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchLeaderboard } from '../../api/cards';
import { getCardRarityClass } from '../../utils/cardUtils';
import { toast } from 'react-toastify';

const LeaderboardPage = () => {
    const [leaderboard, setLeaderboard] = useState({ batters: [], pitchers: [] });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await fetchLeaderboard();
                setLeaderboard(data);
            } catch (error) {
                toast.error('Error fetching leaderboard: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    if (loading) {
        return <p>Loading...</p>;
    }
    return (
        <div className="leader-container">
            <h1 className="header">
                Leaderboard | <Link to={`/leaderboard/elo`}>Team Rating</Link>
            </h1>

            {/* Batters Section */}
            <div>
                <h2>Batters</h2>
            </div>
            <div className="leaderboard-grid">
                {leaderboard.batters.map(statCategory => (
                    <div key={statCategory.stat} className="stat-category">
                        <h3>{statCategory.stat.toUpperCase()}</h3>
                        <ul>
                            {statCategory.players.map(player => (
                                <li key={player.cardID}>
                                    <Link to={`/profile/${player.User.userID}`}>{player.User.username}</Link>
                                    &nbsp;
                                    <span className={`square ${getCardRarityClass(player.Card.rating)}`}></span>(
                                    {player.Card.rating}) {player.Card.year} {player.Card.name}:{' '}
                                    {player[statCategory.stat]} (in {player.ab} AB)
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>

            {/* Pitchers Section */}
            <div>
                <h2>Pitchers</h2>
            </div>
            <div className="leaderboard-grid">
                {leaderboard.pitchers.map(statCategory => (
                    <div key={statCategory.stat} className="stat-category">
                        <h3>{statCategory.stat.toUpperCase()}</h3>
                        <ul>
                            {statCategory.players.map(player => (
                                <li key={player.cardID}>
                                    <Link to={`/profile/${player.User.userID}`}>{player.User.username}</Link>
                                    &nbsp;
                                    <span className={`square ${getCardRarityClass(player.Card.rating)}`}></span>(
                                    {player.Card.rating}) {player.Card.year} {player.Card.name}:{' '}
                                    {player[statCategory.stat]} (in {((player.ipOuts || 0) / 3).toFixed(1)} IP)
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LeaderboardPage;

import React, { useEffect, useState } from 'react';
import { fetchAllTeams } from '../../api/userTeams';
import { calcAllOldElo } from '../../api/games';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';

const LeaderboardPage = () => {
    const { currentUser } = useAuth();
    const [leaderboard, setLeaderboard] = useState();
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchAllTeams();
            setLeaderboard(data || []);
        };

        fetchData();
    }, []);

    const handleSubmitElo = async () => {
        setSubmitting(true);
        try {
            await calcAllOldElo();
            toast.success('Elo updated successfully!');
        } catch (error) {
            toast.error('Error updating elo: ' + error.message);
        } finally {
            setSubmitting(false);
        }
    };
    return (
        <div className="elo-container">
            <h1 className="header">Top Teams Leaderboard</h1>
            {currentUser && currentUser.isAdmin && (
                <div>
                    Score all Old Elo Games
                    <button onClick={handleSubmitElo} disabled={submitting}>
                        {submitting ? 'Scoring...' : 'Score'}
                    </button>
                </div>
            )}
            <div>
                <h2>Teams</h2>
                {(leaderboard || []).map(team => (
                    <div key={team.userTeamID}>
                        {team.elo} - {team.name}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LeaderboardPage;
